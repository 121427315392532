<template>
  <div>
    <div :class="{'result-bar-fixed':resultBarFixed}">
      <div class="safe-area flex-row align-items-center justify-between" style="height:50px">
        <div class="flex-row align-items-center">
          <div class="link border-right padding-right-10 margin-right-10 color-sub" @click="$router.go(-1)"><i class="el-icon-back"></i>返回</div>
          <span class="bold">
            <span v-if="$store.state.currCustomer.dwmch">
             客户：{{$store.state.currCustomer.dwmch }}
            </span>
            <span v-else>填写并核对订单信息</span>
          </span>
          <span class="color-orange">（请尽快确认并提交订单，以免因库存变化导致无法下单）</span>
        </div>
        
      </div>
    </div>
    <div style="height: 50px;" v-if="resultBarFixed"></div>
    <div class="safe-area">
      <div class="column" ref="main" v-loading="loading">
        <div class="border-bottom padding-bottom-20">
          <div class="flex-row align-items-center justify-between">
            <div><span class="bold font-size-16">收货人信息</span></div>
            <div class="flex-row">
              <div class="link margin-right-20" @click="addAddressVisible=true"><i class="el-icon-circle-plus-outline"></i> 新增收货地址</div>
              <div class="link" @click="$root.jump({path:'/user/address'})"><i class="el-icon-edit"></i> 地址管理</div>
            </div>
            
          </div>
          <div v-if="addressList.length">
            <div class="address-list margin-top-20">
              <div class="address-item flex-row align-items-center margin-bottom-10 link">
                <div class="address-name active">{{curAddress.user_name}}</div>
                <div class="margin-right-10">{{curAddress.province}} {{curAddress.city}} {{curAddress.district}} {{curAddress.address_detail}} {{curAddress.user_phone}}</div>
                <el-tag v-if="curAddress.is_default" size="mini">默认地址</el-tag>
              </div>
              <div v-show="showMoreAddress" style="max-height: 500px; overflow: auto;">
                <div class="address-item flex-row align-items-center margin-bottom-10 link"
                 v-for="(item,index) in addressList.filter(v=>{return v.address_id!=curAddress.address_id})" :key="index"
                 @click="curAddress=item;showMoreAddress=false;">
                  <div class="address-name">{{item.user_name}}</div>
                  <div class="margin-right-10">{{item.province}} {{item.city}} {{item.district}} {{item.address_detail}} {{item.user_phone}}</div>
                  <el-tag v-if="item.is_default" size="mini">默认地址</el-tag>
                </div>
              </div>
              
            </div>
            <div class="link" v-if="addressList.length>1" @click="showMoreAddress=!showMoreAddress">更多地址 <i :class="showMoreAddress?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
          </div>
          <div v-else>
            <div class="margin-top-10 color-red"><i class="el-icon-location-outline"></i> 暂无收货地址信息，请添加收货地址</div>
          </div>
        </div>
        <div class="border-bottom padding-tb-20 flex-row align-items-center">
          <div><span class="bold font-size-16">仓库地址</span></div>
          <div class="margin-left-20 flex-auto">
              <el-select v-model="curWarehouse" placeholder="请选择" style="width: 100%;">
                <el-option v-for="(item,index) in warehouseList" :key="index" :value="item.ckdz1" :label="item.ckdz1"></el-option>
              </el-select>
          </div>
        </div>
        <div class="border-bottom padding-tb-20">
          <div class="flex-row justify-between">
            <div><span class="bold font-size-16">商品清单</span>({{ products.length }})</div>
            <div class="link" @click="prodListShow=!prodListShow"><span class="link-text">{{prodListShow?'收起':'展开'}}</span> <i :class="prodListShow?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
          </div>
          <div v-show="prodListShow" class="margin-top-20">
            <el-table
              :data="products"
              size="medium"
              :show-header="false"
              style="width: 100%"
            >
              <el-table-column label="商品信息"  min-width="200">
                <template slot-scope="props">
                  <div class="flex-row">
                    <div><el-image class="cart-item-pic border" :src="props.row.img_url" fit="contain">
                      <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                        <img src="@/assets/images/no-pic.png" height="80px"/>
                      </div>
                    </el-image></div>
                    <div class="flex-auto margin-left-10 line-height-small">
                      <div class="bold">{{props.row.spmch}}</div>
                      <div class="color-sub font-size-12">{{props.row.shengccj}}</div>
                      <div class="color-sub font-size-12">{{props.row.shpgg}}</div>
                      <div class="color-sub font-size-12">批号：{{props.row.pihao}}</div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="单价">
                <template slot-scope="props">单价：￥{{ props.row.sp_buy_jiage  | numFormat(2)}}</template>
              </el-table-column>
              <el-table-column label="数量">
                <template slot-scope="props">
                  数量：{{props.row.sp_buy_sl}}
                </template>
              </el-table-column>
              <el-table-column label="金额" align="right">
                <template slot-scope="props">金额：<span class="color-orange">￥{{ (props.row.sp_buy_jiage * props.row.sp_buy_sl) | numFormat(2)}}</span></template>
              </el-table-column>
            </el-table>
            <div class="text-align-center link" @click="prodListShow=!prodListShow" style="margin:0 auto;width: 50px; border-radius: 0 0 5px 5px/0 0 5px 5px; background: #f7f7f7;"><i :class="prodListShow?'el-icon-arrow-up':'el-icon-arrow-down'"></i></div>
          </div>
        </div>
        <div class="order-props">
          
          <div class="border-bottom padding-tb-20">
            <div><span class="bold font-size-16">配送方式</span></div>
            <div class="margin-top-20 flex-row">
              <div>
                配送：
                <el-select v-model="peisong.value" placeholder="请选择">
                  <el-option v-for="(item,index) in peisong.items" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </div>
              <div class="margin-left-30">
                运费付款方式：
                 <el-select v-model="yunfeifangshi.value" placeholder="请选择">
                  <el-option v-for="(item,index) in yunfeifangshi.items" :key="index" :value="item" :label="item"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="border-bottom padding-tb-20">
            <div class="flex-row align-items-center">
              <div><span class="bold font-size-16">发票</span></div>
              <div class="margin-left-20 padding-left-20 border-left">
                随货发送：
                <el-switch v-model="fapiao.status"></el-switch>
              </div>
            </div>
            <div class="margin-top-20">
              <span class="color-sub">选择：</span>
              <el-radio-group v-model="fapiao.value">
                <el-radio-button v-for="(item,index) in fapiao.items" :key="index" :label="item"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="border-bottom padding-tb-20">
            <div class="flex-row align-items-center">
              <div><span class="bold font-size-16">合单</span></div>
              <div class="margin-left-20 padding-left-20 border-left">
                是否合单：<el-switch v-model="hedan.status"></el-switch>
              </div>
            </div>
            <div class="margin-top-20 flex-row" v-if="hedan.status">
              <div><el-input placeholder="请输入" v-model="hedan.name"> <template slot="prepend">合单收货人:</template></el-input></div>
              <div class="margin-left-20"><el-input placeholder="请输入手机号" v-model="hedan.phone"> <template slot="prepend">收货人电话:</template></el-input></div>
            </div>
          </div>
          <div class="border-bottom padding-tb-20">
            <div><span class="bold font-size-16">其他资料</span><span class="color-sub">（可多选）</span></div>
            <div class="margin-top-20 flex-row align-items-center">
              <span class="color-sub">选择：</span>
              <el-checkbox-group v-model="ziliao.value">
                <el-checkbox-button v-for="(item,index) in ziliao.items" :key="index" :label="item"></el-checkbox-button>
              </el-checkbox-group>
              <div class="flex-auto margin-left-20"><el-input v-model="ziliao.text" placeholder="请输入其他资料"> <template slot="prepend">其他资料:</template></el-input></div>
            </div>
          </div>
          <div class="border-bottom padding-tb-20">
            <div><span class="bold font-size-16">支付方式</span></div>
            <div class="margin-top-20">
              <span class="color-sub">选择：</span>
              <el-radio-group v-model="zhifu.value">
                <el-radio-button v-for="(item,index) in zhifu.items" :key="index" :label="item"></el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <div class="border-bottom padding-tb-20">
            <div class="bold font-size-16">留言</div>
            <div class="margin-top-20">
              <div><el-input v-model="liuyan" placeholder="建议留言前与商家沟通确认"></el-input></div>
            </div>
          </div>
          <div class="border-bottom padding-tb-20" v-if="youhuiquan.items.length">
            <div class="bold" font-size-16>优惠券</div>
            <div class="margin-top-20 coupon-list">
              <el-checkbox-group v-model="youhuiquan.value">
                <el-checkbox :label='item.yhq_id' v-for="(item,index) in youhuiquan.items" :key="index" @change="yhqNewMoney($event,item.yhq_id)">

                    <div class="item margin-bottom-15">
                      <div class="detail flex-row align-items-center justify-between">
                        <div class="flex-row align-items-center">
                          <div class="je bold"><span class="font-size-20">{{item.yhq_je}}</span></div>
                          <div class="main">
                            <div class="font-size-18">{{item.yhq_lx_sm}}</div>
                            <div class="margin-top-5 font-size-12">有效期：{{item.yxq_start}} 至 {{item.yxq_end}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="memo color-sub border font-size-12">使用规则：{{item.yhq_gz}}</div>
                    </div>

                </el-checkbox>
              </el-checkbox-group>
              
            </div>
          </div>
        </div>
      </div>
      <div :class="{'bottom-result-bar-Fixed':bottomResultBarFixed}">
        <div class="column flex-row align-items-center justify-between">
          <div>
            <div class="flex-row align-items-center">
              <div class="margin-right-20"><span class="color-sub">总商品金额：</span>￥{{totalPrice | numFormat(2)}}</div>
              <div class="margin-right-20"><span class="color-sub">优惠抵扣：</span>￥{{(totalPrice-sfPrice) | numFormat(2)}}</div>
            </div>
            <div class="margin-top-10"><span class="color-sub">寄送至：</span>{{curAddress.user_name}} {{curAddress.province}} {{curAddress.city}} {{curAddress.district}} {{curAddress.address_detail}} {{curAddress.user_phone}}</div>
          </div>
          <div class="flex-row align-items-center">
            <div class=" margin-left-30">应付总额： <span class="color-orange">￥</span><span class="color-orange bold font-size-20">{{(sfPrice||totalPrice) | numFormat(2)}}</span></div>
            <div class="margin-left-30"><el-button type="primary" round :disabled="!this.q.selected_products" :loading="submitLoading" @click="submit">提交订单</el-button></div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog custom-class="custom-dialog" title="添加地址信息"  :visible.sync="addAddressVisible" :append-to-body="true" width="50%" top="15vh">
      <div>
        <AddressForm :q="{}" @success="getAddressList();addAddressVisible=false;"></AddressForm>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddressForm from '@/components/AddressForm.vue'
import crypto from "@/common/crypto";
export default {
  components: { AddressForm },
  name: 'SubmitOrder',
  data() {
    return {
      crypto,
      loading:false,
      resultBarFixed:false,
      bottomResultBarFixed:false,
      addAddressVisible:false,
      addressList:[],
      curAddress:{},
      showMoreAddress:false,
      warehouseList:[],
      curWarehouse:'',
      prodListShow:true,
      products:[],
      totalPrice:0,
      sfPrice:0,
      peisong:{
        items:[],
        value:'',
      },
      yunfeifangshi:{
        items:[],
        value:'',
      },
      fapiao:{
        status:false,
        items:[],
        value:'',
        activevAalue:'',
        selectedValue:''
      },
      hedan:{
        status:false,
        name:'',
        phone:''
      },
      ziliao:{
        items:[],
        value:[],
        text:''
      },
      zhifu:{
        items:[],
        value:'',
      },
      liuyan:'',
      youhuiquan:{
        items:[],
        value:[],
      },
      submitLoading:false,
    }
  },
  computed:{
    q(){
      let q=null
      try {
        q=this.$store.state.submitOrderParams||JSON.parse(sessionStorage.getItem('submitOrderParams'))
      } catch (error) {
        q=this.$store.state.submitOrderParams
      }
      return q||{}
    },
  },
  methods:{
    getData(){
      if(this.q.selected_products){
        this.getOrderData()
        this.getAddressList()
        this.getWarehouseList()
      }else{
        this.$alert('当前没有要结算的商品，请去购物车结算或商品详情页购买', '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.go(-1)
        })
      }
    },
    handleScroll(){
      if(document.documentElement.scrollTop>120){
        this.resultBarFixed=true
      }else{
        this.resultBarFixed=false
      }
      if(this.$refs.main.getBoundingClientRect().bottom>this.$root.docHeight){
        this.bottomResultBarFixed=true
      }else{
        this.bottomResultBarFixed=false
      }
    },
    getOrderData(){
      this.loading=true
      let params= {
        ...this.$store.state.basicParams,
        selected_products:JSON.stringify(this.q.selected_products),
        belonger_user_id:this.q.belonger_user_id,
        settlement_type:this.q.settlement_type
      }
      this.$instance.post('cart/product_settlement',this.$qs.stringify(params))
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.products=res.data.data.selected_products
          this.totalPrice=res.data.data.total_fee
          this.sfPrice=res.data.data.total_fee

          this.peisong.items=res.data.data.delivery_type
          this.peisong.value=res.data.data.delivery_type[0]

          this.yunfeifangshi.items=res.data.data.freight_payment_type
          this.yunfeifangshi.value=res.data.data.freight_payment_type[0]

          this.fapiao.items=res.data.data.invoice_type
          this.fapiao.value=res.data.data.invoice_type[0]

          this.ziliao.items=res.data.data.other_data
          this.zhifu={items:res.data.data.payment_type,value:res.data.data.payment_type[0]}
          this.getCouponList()
        }else{
          this.$message.closeAll()
          this.$alert(res.data.msg||'结算下单异常，请稍后再试', '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '确定',
            showClose:false,
            type: 'warning'
          })
          .then(() => {
            this.$router.go(-1)
          })
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    },
    //获取地址列表
    getAddressList(){
      let params= {
        ...this.$store.state.basicParams,
        page:1,
        page_size:1000,
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/address/get_user_address_list',{params})
      .then(res=>{
        if(res.data.code==0){
          this.addressList=res.data.data.list
          if(this.addressList.length){
            this.curAddress=this.addressList.find(v=>{return v.is_default})||this.addressList[0]
          }
        }
      })
      .catch(()=> {}); 
    },
    //获取仓库列表
    getWarehouseList(){
      let params= {
        ...this.$store.state.basicParams,
        belonger_user_id:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/address/get_warehouse_list',{params})
      .then(res=>{
        if(res.data.code==0){
          this.warehouseList=res.data.data
          if(this.warehouseList.length){
            this.curWarehouse=this.warehouseList[0].ckdz1
          }
        }
      })
      .catch(()=> {}); 
    },
    //手机号验证格式
    verPhone(p, required = true) {
      if (!p) {
        return required ? false: true;
      } else {
        // 必须是1开头，第二位数字可以是0-9任意一个，总长为11
        let reg = /^1([0-9])\d{9}$/;
        // 必须是1开头，第二位数字可以是3|5|6|7|8|9任意一个，总长为11
        // let reg = /^1([3|5|6|7|8|9])\d{9}$/;
        if (reg.test(p)) {
          return true;
        } else {
          return false;
        }
      }
    },
    //提交订单
   submit(){
     if(!this.curAddress.address_id){
        this.$message({message: '请添加收货人信息',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
       return false
     }
     if(!this.curWarehouse){
        this.$message({message: '请选择仓库地址，如未维护仓库地址请联系业务人员',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
       return false
     }
     if(this.hedan.status&&(!this.hedan.name||!this.hedan.phone)){
        this.$message({message: '请输入合单人姓名和电话',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
       return false
     }
     if(this.hedan.status&&!this.verPhone(this.hedan.phone)){
        this.$message({message: '请输入正确手机号',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
       return false
     }
     this.submitLoading=true
      let params= {
        ...this.$store.state.basicParams,
        selected_products:JSON.stringify(this.q.selected_products),
        belonger_user_id:this.q.belonger_user_id,
        settlement_type:this.q.settlement_type,
        address_id:this.curAddress.address_id,
        ckdz:this.curWarehouse,
        delivery_type:this.peisong.value,
        freight_payment_type:this.yunfeifangshi.value,
        invoice_type:this.fapiao.value,
        invoice_is_send:this.fapiao.status?'是':'否',
        is_hd:this.hedan.status?'是':'否',
        hd_user_name:this.hedan.status?this.hedan.name:undefined,
        hd_user_phone:this.hedan.status?this.hedan.phone:undefined,
        other_data:this.ziliao.value.join(","),
        other_data_memo:this.ziliao.text,
        leave_message:this.liuyan,
        payment_type:this.zhifu.value,
        yhq_id:this.youhuiquan.value.join(","),
      }
      this.$instance.post('cart/submit_order',this.$qs.stringify(params))
      .then(res=>{
        this.submitLoading=false
        if(res.data.code==0){
          this.$store.dispatch('gwcNum')
           this.$confirm('订单提交成功！', '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '查看订单',
            cancelButtonText: '继续购买',
            showClose:false,
            type: 'success'
          })
          .then(() => {
            this.$router.replace({
              path:'/user/order'
            })
          })
          .catch(() => {
            this.$router.go(-1)
          });
        }
      })
      .catch(()=> {
        this.submitLoading=false
      }); 
   },
   //获取优惠券列表
    getCouponList(){
      let params= {
        ...this.$store.state.basicParams,
        selected_products:JSON.stringify(this.q.selected_products),
        belonger_user_id:this.q.belonger_user_id,
      }
      this.$instance.post('/yhq/get_can_use_yhq_list',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          this.youhuiquan.items=res.data.data
        }
      })
      .catch(()=> {}); 
    },
    //计算使用优惠券后金额
    yhqNewMoney(event,id){
      let yhq=[...this.youhuiquan.value]
      event&&yhq.splice(yhq.findIndex(v=>{return v==id}),1)
      console.log(event,id,yhq)

      let params= {
        ...this.$store.state.basicParams,
        selected_products:JSON.stringify(this.q.selected_products),
        belonger_user_id:this.q.belonger_user_id,
        select_type:event?1:2,
        yhq_id:id,
        selected_yhq:yhq.join(","),
      }
      this.$instance.post('/yhq/calc_choose_yhq_new_money',this.$qs.stringify(params))
      .then(res=>{
        if(res.data.code==0){
          this.sfPrice=res.data.data.sf_je
        }else{
          if(event){
            this.youhuiquan.value.splice(this.youhuiquan.value.findIndex(v=>{return v==id}),1)
          }else{
            this.youhuiquan.value.push(id)
          }
          
        }
      })
      .catch(()=> {}); 
    },
  },
  created(){
    this.getData()
  },
  mounted(){ 
    document.addEventListener('scroll', this.handleScroll)
    this.$nextTick(()=>{
      this.handleScroll()
    })
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .result-bar-fixed{
    position: fixed;
    left: 0;
    right: 0;
    top: 40px;
    background: #f7f7f7;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  }
  .bottom-result-bar-Fixed{position: fixed;
    left: 0;
    right: 0;
    bottom: 0px;
    background: #f7f7f7;
    z-index: 5;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    .column{
      border-radius: 0;
      margin: 0 auto;
      width: 1200px;
      box-sizing: border-box;
    }
  }
  .cart-item-pic{
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
  .address-list{
    .address-item:hover{
      background: #ffebe1;
    }
    .address-name{
      min-width: 120px;
      text-align: center;
      padding: 5px 10px;
      margin-right:20px;
      border: #ddd solid 1px;
      background: #fff;
    }
    div.active{
      border: #fe5e12 solid 2px;
      position: relative;
    }
    div.active::before{
      content: '';
      position: absolute;
      border-right: #fe5e12 solid 8px;
      border-bottom: #fe5e12 solid 8px;
      border-left:transparent solid 8px;
      border-top: transparent solid 8px;
      height: 0;
      width: 0;
      bottom:0;
      right: 0;
      color: #fff;
    }
    div.active::after{
      content: '✔';
      font-size: 10px;
      position: absolute;
      bottom:0;
      right: 0;
      line-height: 1em;
      color: #fff;
    }
  }
  // .order-props>div{
  //   padding:20px;
  // }
  // .order-props>div:nth-child(odd){
  //   background: #f7f7f7;
  // }
  .coupon-list{
  .item {
    .detail{
      position: relative;
      z-index: 2;
      background: #ffe0e0;
      border-radius: 10px;
      padding: 10px 20px;
      .je{
        color: #cf0000;
        width: 100px;
        text-align: center;
      }
      .main{
        color: #8c0000;
      }  
    }
    .detail::before,.detail::after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #fff;
      margin-top: -10px;
      top:50%;
    }
    .detail::before{
      left:-10px;
    }
    .detail::after{
      right:-10px;
    }
    .memo{
      border-radius: 10px;
      padding: 20px 20px 5px 20px;
      position: relative;
      z-index: 1;
      margin-top:-15px;
    }
  }
 }
</style>
<style>
.coupon-list .el-checkbox__label{
  vertical-align: middle;
}
</style>
